var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "props-edit-service3" }, [
    _vm.dataset.props.filter((item) => item.active).length === 0
      ? _c("div", { staticClass: "example-container" }, [
          _c(
            "div",
            {
              staticClass: "the-one single-card",
              style: {
                backgroundImage:
                  "url(" +
                  require(`@/assets/images/lego/${_vm.theme}/service_card_back.png`) +
                  ")",
              },
            },
            [
              _c("div", { staticClass: "title-line overflow-ellipse" }, [
                _vm._v(
                  "\n        " + _vm._s(_vm.dataset.props[0].title) + "\n      "
                ),
              ]),
              _c("div", { staticClass: "description-line" }, [
                _vm._v(
                  "\n        " +
                    _vm._s(_vm.dataset.props[0].description) +
                    "\n      "
                ),
              ]),
              _c("div", { staticClass: "card-icon" }, [
                _c("img", {
                  attrs: {
                    src: _vm.imageRender(_vm.dataset.props[0].background, 0),
                    alt: "",
                  },
                }),
              ]),
            ]
          ),
          _c("div", { staticClass: "right-part" }, [
            _c("div", { staticClass: "the-two single-card" }, [
              _c("div", { staticClass: "left-lines" }, [
                _c("div", { staticClass: "title-line overflow-ellipse" }, [
                  _vm._v(
                    "\n            " +
                      _vm._s(_vm.dataset.props[1].title) +
                      "\n          "
                  ),
                ]),
                _c(
                  "div",
                  { staticClass: "description-line overflow-ellipse" },
                  [
                    _vm._v(
                      "\n            " +
                        _vm._s(_vm.dataset.props[1].description) +
                        "\n          "
                    ),
                  ]
                ),
              ]),
              _c("div", { staticClass: "icon-container" }, [
                _c("img", {
                  attrs: {
                    src: _vm.imageRender(_vm.dataset.props[1].background, 1),
                    alt: "",
                  },
                }),
              ]),
            ]),
            _c("div", { staticClass: "the-three single-card" }, [
              _c("div", { staticClass: "left-lines" }, [
                _c("div", { staticClass: "title-line overflow-ellipse" }, [
                  _vm._v(
                    "\n            " +
                      _vm._s(_vm.dataset.props[2].title) +
                      "\n          "
                  ),
                ]),
                _c(
                  "div",
                  { staticClass: "description-line overflow-ellipse" },
                  [
                    _vm._v(
                      "\n            " +
                        _vm._s(_vm.dataset.props[2].description) +
                        "\n          "
                    ),
                  ]
                ),
              ]),
              _c("div", { staticClass: "icon-container" }, [
                _c("img", {
                  attrs: {
                    src: _vm.imageRender(_vm.dataset.props[1].background, 2),
                    alt: "",
                  },
                }),
              ]),
            ]),
          ]),
        ])
      : _c(
          "div",
          [
            _c("SingleFunctionProps", {
              ref: "functionPropsIns",
              attrs: {
                dataset: _vm.dataset.props.filter((item) => item.active)[0],
              },
            }),
          ],
          1
        ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }