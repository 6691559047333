var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "mini-info-edit" }, [
    _vm.mapShow
      ? _c(
          "div",
          { staticClass: "map-container-box" },
          [
            _c(
              "div",
              { staticClass: "map-search-line" },
              [
                _c("el-input", {
                  staticStyle: { width: "100%", "margin-right": "20px" },
                  attrs: { placeholder: "请输入地点" },
                  model: {
                    value: _vm.mapKeyword,
                    callback: function ($$v) {
                      _vm.mapKeyword = $$v
                    },
                    expression: "mapKeyword",
                  },
                }),
              ],
              1
            ),
            _c(
              "baidu-map",
              {
                staticClass: "map-container",
                attrs: {
                  center: {
                    lng: _vm.mapSiteObj.longitude,
                    lat: _vm.mapSiteObj.latitude,
                  },
                  zoom: 16,
                },
                on: {
                  moving: _vm.syncCenterAndZoom,
                  moveend: _vm.syncCenterAndZoom,
                  zoomend: _vm.syncCenterAndZoom,
                },
              },
              [
                _c("bm-local-search", {
                  attrs: { keyword: _vm.mapKeyword, "auto-viewport": false },
                  on: {
                    infohtmlset: _vm.infoSelectHanlder,
                    markersset: _vm.showSuggestion,
                  },
                }),
                _c("bm-marker", {
                  attrs: {
                    position: {
                      lng: _vm.mapSiteObj.longitude,
                      lat: _vm.mapSiteObj.latitude,
                    },
                    dragging: true,
                  },
                  on: { dragend: _vm.markerDragOver },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "map-buttons-container" },
              [
                _c(
                  "r-button",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.showFooterButtons,
                        expression: "showFooterButtons",
                      },
                    ],
                    attrs: { type: "cancel" },
                    on: {
                      click: function ($event) {
                        _vm.mapShow = false
                      },
                    },
                  },
                  [_vm._v("取消")]
                ),
                _c(
                  "r-button",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.showFooterButtons,
                        expression: "showFooterButtons",
                      },
                    ],
                    attrs: { plain: "" },
                    on: { click: _vm.mapSaveAndClose },
                  },
                  [_vm._v("保存并返回")]
                ),
              ],
              1
            ),
          ],
          1
        )
      : _vm._e(),
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: !_vm.mapShow,
            expression: "!mapShow",
          },
        ],
      },
      [
        _c(
          "el-form",
          {
            ref: "formIns",
            attrs: {
              "label-position": "top",
              model: _vm.form,
              rules: _vm.formRule,
            },
          },
          [
            _c(
              "el-form-item",
              { attrs: { label: "小程序名称", prop: "miniprogramTitle" } },
              [
                _c("el-input", {
                  attrs: { placeholder: "请输入小程序名称" },
                  model: {
                    value: _vm.form.miniprogramTitle,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "miniprogramTitle", $$v)
                    },
                    expression: "form.miniprogramTitle",
                  },
                }),
              ],
              1
            ),
            _c(
              "el-form-item",
              { attrs: { label: "选择区域", prop: "areaId" } },
              [
                _c(
                  "el-select",
                  {
                    staticStyle: { width: "100%" },
                    attrs: { placeholder: "请选择区域" },
                    model: {
                      value: _vm.form.areaId,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "areaId", $$v)
                      },
                      expression: "form.areaId",
                    },
                  },
                  _vm._l(_vm.areaOptions, function (item) {
                    return _c("el-option", {
                      key: item.areaId,
                      attrs: { value: item.areaId, label: item.areaName },
                    })
                  }),
                  1
                ),
              ],
              1
            ),
            _c(
              "el-form-item",
              { attrs: { label: "园区地址", prop: "miniprogramAddress" } },
              [
                _c("el-input", {
                  attrs: { placeholder: "请输入园区地址" },
                  model: {
                    value: _vm.form.miniprogramAddress,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "miniprogramAddress", $$v)
                    },
                    expression: "form.miniprogramAddress",
                  },
                }),
              ],
              1
            ),
            _c(
              "el-form-item",
              {
                staticClass: "position-container",
                attrs: { label: "园区坐标", prop: "miniprogramLocation" },
              },
              [
                _c("el-input", {
                  attrs: { placeholder: "请输入园区坐标" },
                  model: {
                    value: _vm.form.miniprogramLocation,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "miniprogramLocation", $$v)
                    },
                    expression: "form.miniprogramLocation",
                  },
                }),
                _c("i", {
                  staticClass: "el-icon-location-outline location-icon",
                  on: { click: _vm.openMap },
                }),
              ],
              1
            ),
            _c(
              "el-form-item",
              { attrs: { label: "绑定项目", prop: "projectIds" } },
              [
                _c(
                  "el-select",
                  {
                    staticStyle: { width: "100%" },
                    attrs: { placeholder: "请选择区域", multiple: "" },
                    model: {
                      value: _vm.form.projectIds,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "projectIds", $$v)
                      },
                      expression: "form.projectIds",
                    },
                  },
                  _vm._l(_vm.projectList, function (item) {
                    return _c("el-option", {
                      key: item.projectId,
                      attrs: { value: item.projectId, label: item.projectName },
                    })
                  }),
                  1
                ),
              ],
              1
            ),
            _c(
              "el-form-item",
              { attrs: { label: "商户号", prop: "wechatIds" } },
              [
                _c(
                  "el-select",
                  {
                    staticStyle: { width: "100%" },
                    attrs: { placeholder: "请选择商户号", multiple: "" },
                    model: {
                      value: _vm.form.wechatIds,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "wechatIds", $$v)
                      },
                      expression: "form.wechatIds",
                    },
                  },
                  _vm._l(_vm.merchantList, function (item) {
                    return _c("el-option", {
                      key: item.id,
                      attrs: { value: item.id, label: item.mchName },
                    })
                  }),
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }