<template>
  <div class="props-edit-service4">
    <div v-if="dataset.props.filter((item) => item.active).length === 0">
      <div class="card-container">
        <div
          class="single-card"
          v-for="(item, index) in dataset.props"
          :key="index"
        >
          <div class="left-lines">
            <div class="title-line overflow-ellipse">
              {{ item.title }}
            </div>
            <div class="description-line overflow-ellipse">
              {{ item.description }}
            </div>
          </div>
          <div class="icon-container">
            <img :src="imageRender(item.background, index)" alt="" />
          </div>
        </div>
      </div>
    </div>
    <div v-else>
      <SingleFunctionProps
        ref="functionPropsIns"
        :dataset="dataset.props.filter((item) => item.active)[0]"
      />
    </div>
  </div>
</template>

<script>
import SingleFunctionProps from "../components/singleFunctionProps.vue";
import { envInfo } from "@/constants/envInfo";

export default {
  name: "props-edit-service4",
  components: {
    SingleFunctionProps,
  },
  props: {
    dataset: {
      type: Object,
      default() {
        return {};
      },
    },
    theme: {
      type: String,
      default() {
        return "blue";
      },
    },
  },
  methods: {
    checkValid() {
      this.$refs.functionPropsIns.checkFormValid();
    },
    imageRender(fileId, index) {
      if (fileId) {
        return (
          envInfo.bgApp.archivePath +
          "/param/archive/download?dlType=DefaultDownload&fType=image&fi=" +
          fileId
        );
      }
      return require(`@/assets/images/lego/${this.theme}/service4_card_${index}.png`);
    },
  },
};
</script>

<style lang="less" scoped>
.props-edit-service4 {
  .card-container {
    height: 160px;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 8px;
    .overflow-ellipse {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    .single-card {
      flex: 1;
      min-width: 165px;
      height: 74px;
      background: #fff;
      border-radius: 5px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0 16px;
      border: 1px solid #f2f2f2;
      .left-lines {
        width: 80px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        .title-line {
          font-weight: 600;
          font-size: 16px;
          color: #30313b;
        }
        .description-line {
          font-weight: 400;
          font-size: 12px;
          color: #828798;
          margin-top: 8px;
        }
      }
      .icon-container {
        height: 46px;
        width: 46px;
        display: flex;
        align-items: center;
        justify-content: center;
        img {
          max-width: 100%;
          max-height: 100%;
        }
      }
    }
  }
}
</style>