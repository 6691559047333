<template>
  <div class="single-function-props">
    <el-form
      ref="propsForm"
      :model="dataset"
      :rules="formRules"
      label-width="120px"
      label-position="top"
    >
      <el-form-item class="single-form-item" label="标题" prop="title">
        <el-input
          maxlength="4"
          v-model="dataset.title"
          placeholder="请输入"
        ></el-input>
      </el-form-item>
      <el-form-item class="single-form-item" label="简介" prop="description">
        <el-input v-model="dataset.description" placeholder="请输入"></el-input>
      </el-form-item>
      <el-form-item class="single-form-item" label="背景图" prop="background">
        <div class="upload-container" v-loading="uploadloading">
          <el-upload
            class="avatar-uploader"
            :action="uploadPath"
            :with-credentials="true"
            :show-file-list="false"
            :on-success="handleAvatarSuccess"
            :before-upload="beforeAvatarUpload"
          >
            <img
              v-if="dataset.background"
              :src="imageRender(dataset.background)"
              class="avatar"
            />
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
          </el-upload>
          <div class="tips-container">
            <div>
              <div>温馨提示：</div>
              <div>最多上传1张，图片大小不能超过2m，图片格式为jpg，png。</div>
              <!-- <div>图片尺寸212*112</div> -->
            </div>
            <div
              v-show="dataset.background"
              class="delete-icon"
              @click="deleteHandler"
            >
              <i class="el-icon-delete"></i>
            </div>
          </div>
        </div>
      </el-form-item>
      <el-form-item
        class="single-form-item no-border"
        label="跳转方式"
        prop="jumpMethod"
      >
        <el-radio-group v-model="dataset.jumpMethod">
          <el-radio label="link">外部链接</el-radio>
          <el-radio label="function">选择功能</el-radio>
          <el-radio label="outer">外部小程序</el-radio>
        </el-radio-group>
        <div v-if="dataset.jumpMethod === 'link'">
          <div class="second-level-title second-level-require">链接地址</div>
          <el-input
            v-model="dataset.linkObj.linkUrl"
            placeholder="请输入"
          ></el-input>
        </div>
        <div v-else-if="dataset.jumpMethod === 'function'">
          <div class="second-level-title second-level-require">选择功能</div>
          <el-cascader
            style="width: 100%"
            v-model="dataset.functionObj.functionId"
            :options="functionList"
            @change="functionChange"
            :props="{ expandTrigger: 'hover' }"
          >
          </el-cascader>
        </div>
        <div v-else-if="dataset.jumpMethod === 'outer'">
          <div class="second-level-title second-level-require">appId</div>
          <el-input
            v-model="dataset.outerObj.appId"
            placeholder="请输入"
          ></el-input>
        </div>
      </el-form-item>
      <!-- 下面两个单独领出来是为了必填校验时不被高亮 -->
      <div v-if="dataset.jumpMethod === 'link'">
        <div class="second-level-title">外部链接说明</div>
        <el-input
          style="margin-top: 10px"
          type="textarea"
          v-model="dataset.linkObj.linkDescription"
          placeholder="请输入"
        ></el-input>
      </div>
      <div v-if="dataset.jumpMethod === 'outer'">
        <div class="second-level-title">页面地址</div>
        <el-input
          v-model="dataset.outerObj.pageUrl"
          placeholder="请输入"
        ></el-input>
      </div>
    </el-form>
    <div class="buttons-line" v-if="source === 'functions'">
      <r-button type="danger" @click="propsDeleteHandler">删除</r-button>
      <r-button plain @click="saveHandler">保存</r-button>
    </div>
  </div>
</template>

<script>
import { envInfo } from "@/constants/envInfo";

export default {
  name: "single-function-props",
  props: {
    dataset: {
      type: Object,
      default() {
        return {};
      },
    },
    // functions | service
    source: {
      type: String,
      default() {
        return "service";
      },
    },
  },
  data() {
    return {
      functionList: [],
      linkTypeList: [
        {
          label: "内部链接",
          value: "inner",
        },
        {
          label: "外部链接",
          value: "outer",
        },
      ],
      uploadloading: false,
      uploadPath:
        envInfo.bgApp.archivePath +
        "/param/archive/upload?ulType=DefaultUpload&scopeType=ALL",
      // form: {
      // title: "",
      // description: "",
      // background: "default", // default | fileId
      // jumpMethod: "link", // link | function
      // linkObj: {
      //   linkType: "outer", // outer | inner
      //   linkUrl: "",
      //   linkDescription: "",
      // },
      // functionObj: {
      //   functionId: null,
      // },
      // },
      formRules: {
        title: [{ required: true, message: "请输入", trigger: "blur" }],
        background: [
          { required: true, message: "请上传图片", trigger: "blur" },
        ],
        jumpMethod: [
          {
            required: true,
            trigger: ["blur", "change"],
            message: this.$t("validate.required"),
          },
          {
            validator: (rule, value, callback) => {
              if (
                this.dataset.jumpMethod === "link" &&
                !this.dataset.linkObj.linkUrl
              ) {
                callback(new Error("请填写链接地址"));
              }
              if (
                this.dataset.jumpMethod === "outer" &&
                !this.dataset.outerObj.appId
              ) {
                callback(new Error("请填写appId"));
              }
              if (
                this.dataset.jumpMethod === "function" &&
                !this.dataset.functionObj.functionId
              ) {
                callback(new Error("请选择功能"));
              }
              callback();
            },
            trigger: ["blur", "change"],
          },
        ],
      },
    };
  },
  created() {
    this.functionList = this.$store.getters.legoFuncList.map((item) => {
      return {
        label: item.functionTypeName,
        value: item.functionType,
        children: item.functionList.map((single) => {
          return {
            label: single.functionName,
            value: single.functionId,
            icon: single.functionIcon,
          };
        }),
      };
    });
    console.log("this.functionList", this.functionList);
  },
  methods: {
    functionChange(value) {
      console.log("value", value);
      const [functionType, functionId] = value || [];
      this.functionList.forEach((item) => {
        if (item.value === functionType) {
          item.children.forEach((single) => {
            if (single.value === functionId && single.icon) {
              this.dataset.background = single.icon;
              console.log("this.dataset.background", this.dataset.background);
            }
          });
        }
      });
    },
    imageRender(fileId) {
      return (
        envInfo.bgApp.archivePath +
        "/param/archive/download?dlType=DefaultDownload&fType=image&fi=" +
        fileId
      );
    },
    handleAvatarSuccess(files) {
      this.dataset.background = files[0].fileId;
      this.uploadloading = false;
    },
    beforeAvatarUpload(file) {
      const isJPG = file.type === "image/jpeg";
      const isPNG = file.type === "image/png";
      const isLt2M = file.size / 1024 / 1024 < 2;

      if (!isJPG && !isPNG) {
        this.$message.error("封面图片只能是 JPG,PNG 格式!");
      }
      if (!isLt2M) {
        this.$message.error("封面图片大小不能超过 2MB!");
      }
      const returnFlag = (isJPG || isPNG) && isLt2M;
      this.uploadloading = returnFlag;
      return returnFlag;
    },
    deleteHandler() {
      this.dataset.background = null;
    },
    async propsDeleteHandler() {
      await this.$confirm(`确定删除此配置吗？`, this.$t("commons.warning"), {
        confirmButtonText: this.$t("commons.confirm"),
        cancelButtonText: "再想想",
        type: "warning",
      });
      this.dataset.checked = false;
      this.dataset.active = false;
    },
    checkFormValid() {
      this.$refs.propsForm.validate();
    },
    async saveHandler() {
      await this.$refs.propsForm.validate();
      this.dataset.checked = true;
      this.dataset.active = false;
      this.$emit("submit", this.dataset);
    },
  },
};
</script>

<style lang="less" scoped>
.single-function-props {
  .single-form-item {
    border-bottom: 1px solid #e3e8ee;
    padding-bottom: 24px;
  }
  .no-border {
    border: none !important;
    padding-bottom: 10px;
  }
  .second-level-title {
    font-weight: 400;
    font-size: 14px;
    color: #5d687c;
    margin-top: 16px;
    margin-bottom: 4px;
  }
  .second-level-require {
    &::before {
      content: "*";
      color: #f56c6c;
      margin-right: 4px;
    }
  }
  .buttons-line {
    margin-top: 20px;
    text-align: right;
  }
}
</style>
<style lang="less">
.upload-container {
  display: flex;
  margin-top: 18px;
  .tips-container {
    margin-left: 15px;
    font-size: 12px;
    font-weight: 400;
    font-size: 12px;
    color: #5d687c;
    & > div {
      height: 20px;
      line-height: 20px;
    }
    .delete-icon {
      position: absolute;
      bottom: 0;
      font-size: 14px;
      color: #ff0000;
      cursor: pointer;
    }
  }
}
.avatar-uploader {
  height: 100px;
  width: 100px;
}
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  height: 100px;
  width: 100px;
  line-height: 100px;
  text-align: center;
}
.avatar {
  height: 100px;
  width: 100px;
  display: block;
}
</style>