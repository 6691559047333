var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "theme-selector" },
    _vm._l(_vm.dataset, function (item) {
      return _c(
        "div",
        {
          key: item.key,
          class: ["single-card", item.active && "single-card-active"],
          on: {
            click: function ($event) {
              return _vm.themeClick(item)
            },
          },
        },
        [
          _c("div", {
            staticClass: "color-line",
            style: {
              background: item.color,
            },
          }),
          _c("div", { staticClass: "label-line" }, [
            _vm._v("\n      " + _vm._s(item.label) + "\n    "),
          ]),
          _c("img", {
            staticClass: "corner-img",
            attrs: {
              src: require(`../../../../../assets/images/bms/example_${
                item.active ? "selected" : "unselected"
              }.png`),
              alt: "",
            },
          }),
        ]
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }