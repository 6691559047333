var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "props-edit-service4" }, [
    _vm.dataset.props.filter((item) => item.active).length === 0
      ? _c("div", [
          _c(
            "div",
            { staticClass: "card-container" },
            _vm._l(_vm.dataset.props, function (item, index) {
              return _c("div", { key: index, staticClass: "single-card" }, [
                _c("div", { staticClass: "left-lines" }, [
                  _c("div", { staticClass: "title-line overflow-ellipse" }, [
                    _vm._v(
                      "\n            " + _vm._s(item.title) + "\n          "
                    ),
                  ]),
                  _c(
                    "div",
                    { staticClass: "description-line overflow-ellipse" },
                    [
                      _vm._v(
                        "\n            " +
                          _vm._s(item.description) +
                          "\n          "
                      ),
                    ]
                  ),
                ]),
                _c("div", { staticClass: "icon-container" }, [
                  _c("img", {
                    attrs: {
                      src: _vm.imageRender(item.background, index),
                      alt: "",
                    },
                  }),
                ]),
              ])
            }),
            0
          ),
        ])
      : _c(
          "div",
          [
            _c("SingleFunctionProps", {
              ref: "functionPropsIns",
              attrs: {
                dataset: _vm.dataset.props.filter((item) => item.active)[0],
              },
            }),
          ],
          1
        ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }