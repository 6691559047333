var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "props-edit-banner" }, [
    _c(
      "div",
      { staticClass: "single-item" },
      [
        _c("div", { staticClass: "item-label-line" }, [_vm._v("banner数量")]),
        _c("el-input-number", {
          attrs: { min: 1, max: 10 },
          on: { change: _vm.freshList },
          model: {
            value: _vm.dataset.props.count,
            callback: function ($$v) {
              _vm.$set(_vm.dataset.props, "count", $$v)
            },
            expression: "dataset.props.count",
          },
        }),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "single-item" },
      [
        _c("div", { staticClass: "item-label-line" }, [_vm._v("banner预览")]),
        _vm._l(_vm.imageList, function (item, index) {
          return _c(
            "div",
            {
              key: index,
              class: ["single-preview", !item && "single-preview-border"],
            },
            [
              item.fileId
                ? _c("el-image", {
                    attrs: {
                      placeholder: "加载中...",
                      src: _vm.imageRender(item.fileId),
                      lazy: "",
                    },
                  })
                : _c("i", { staticClass: "el-icon-picture-outline" }),
            ],
            1
          )
        }),
      ],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }