var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "props-edit-functions" }, [
    _vm.dataset.props.filter((item) => item.active).length === 0
      ? _c("div", [
          _vm.currentPage === "more"
            ? _c(
                "div",
                [
                  _c("div", { staticClass: "label-line" }, [_vm._v("标题")]),
                  _c("el-input", {
                    model: {
                      value: _vm.dataset.label,
                      callback: function ($$v) {
                        _vm.$set(_vm.dataset, "label", $$v)
                      },
                      expression: "dataset.label",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _c(
            "div",
            { staticClass: "example-container" },
            _vm._l(
              _vm.dataset.props.filter((item) => item.checked),
              function (item, index) {
                return _c("div", { key: index, staticClass: "single-card" }, [
                  _c("img", {
                    staticClass: "card-icon",
                    attrs: { src: _vm.imageRender(item.background), alt: "" },
                  }),
                  _c("div", { staticClass: "card-title" }, [
                    _vm._v("\n          " + _vm._s(item.title) + "\n        "),
                  ]),
                ])
              }
            ),
            0
          ),
        ])
      : _c(
          "div",
          [
            _c("SingleFunctionProps", {
              ref: "functionPropsIns",
              attrs: {
                source: "functions",
                dataset: _vm.dataset.props.filter((item) => item.active)[0],
              },
              on: { submit: _vm.submitHandler },
            }),
          ],
          1
        ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }