<template>
  <div class="single-graph-item">
    {{ dataset.label }}
  </div>
</template>

<script>
export default {
  name: "single-graph-item",
  props: {
    dataset: {
      type: Object,
      default() {
        return {};
      },
    },
  },
};
</script>

<style lang="less" scoped>
.single-graph-item {
  height: 150px;
}
</style>