<template>
  <div class="mini-edit-page">
    <div class="header-line">
      <div class="left-part">
        <div class="back-div" @click="routeBack">
          <img src="@/assets/images/go_back_icon.png" alt="" />
          <span>返回</span>
        </div>
        <div class="border-line"></div>
        <div class="name-div">
          <span v-if="!miniprogramTitleEdit" class="name-label">
            {{ miniResource.miniprogramTitle }}
          </span>
          <el-input
            class="name-input"
            ref="miniprogramTitleInput"
            v-else
            v-model="miniResource.miniprogramTitle"
            maxlength="30"
            :show-word-limit="true"
            @blur="changeEditState(false)"
            @keyup.native.enter="changeEditState(false)"
          ></el-input>
          <img
            class="edit-icon"
            src="@/assets/images/bms/edit_icon.png"
            alt=""
            @click="changeEditState(true)"
          />
        </div>
        <div class="update-time">
          最近保存时间: {{ updateTime | dateFormat("YYYY-MM-DD HH:mm:ss") }}
        </div>
      </div>
      <div class="right-part">
        <r-button type="normal" @click="publishHandler('PUBLISH')">
          <i class="el-icon-position" style="margin-right: 4px"></i>
          发布</r-button
        >
        <r-button type="normal" plain @click="updateHandler()">保存</r-button>
      </div>
    </div>
    <div class="main-line">
      <div class="component-part">
        <div
          class="single-catelog"
          v-for="item in componentsList"
          :key="item.key"
        >
          <div class="catelog-title" @click="item.active = !item.active">
            <div class="label-part">
              <img :src="item.icon" alt="" />
              <span class="label-span">
                {{ item.label }}
              </span>
            </div>
            <div class="icon-part">
              <i class="el-icon-caret-right" v-if="!item.active"></i>
              <i class="el-icon-caret-bottom" v-else></i>
            </div>
          </div>
          <div
            :style="{
              height: item.active ? 'auto' : '0',
              overflow: 'hidden',
            }"
          >
            <div v-if="item.key === 'theme'">
              <ThemeSelector
                :dataset="item.list"
                @updateTheme="themeSetHandler"
              />
            </div>
            <div v-else>
              <VueDraggable
                v-model="item.list"
                animation="150"
                ghostClass="left-ghost"
                class="container component-list"
                :sort="false"
                :group="{ name: 'components', pull: 'clone', put: false }"
                filter=".ignore"
                :clone="cloneHandler"
              >
                <div
                  class="single-component"
                  :data-type="single.key"
                  v-for="single in item.list"
                  :key="single.key"
                >
                  <img class="component-image" :src="single.icon" alt="" />
                  <div class="component-label">
                    {{ single.label }}
                  </div>
                </div>
              </VueDraggable>
            </div>
          </div>
        </div>
      </div>
      <div class="graph-part" @click.stop="setItemActive">
        <div
          class="graph-border"
          :style="{
            backgroundImage:
              'url(' +
              require(`@/assets/images/lego/home_background_${pageConfigs.theme}.png`) +
              ')',
          }"
        >
          <div class="mini-name-line">
            <i class="el-icon-location-outline"></i>
            <span class="name-span">
              {{ miniResource.miniprogramTitle }}
            </span>
            <i class="el-icon-arrow-down"></i>
          </div>
          <img src="@/assets/images/lego/home_head_img.png" alt="" />
          <VueDraggable
            v-model="pageConfigs[currentPage]"
            animation="150"
            ghostClass="drag-style"
            class="container"
            group="components"
            filter=".ignore"
            @add="addHandler"
          >
            <div
              :class="[
                `single-item-${item.key}`,
                `single-item`,
                item.active && 'single-item-active',
              ]"
              :id="item.id"
              v-for="item in pageConfigs[currentPage]"
              :key="item.id"
              @click.stop="setItemActive(item)"
            >
              <component
                :is="item.key"
                :key="item.id"
                :dataset="item"
                :active="item.active"
                :theme="pageConfigs.theme"
                :currentPage="currentPage"
                @setActive="setItemActive(item)"
                ref="graphSingleRef"
              ></component>
              <div
                class="delete-container"
                v-if="item.active"
                @click="deleteItem(item)"
              >
                <img src="@/assets/images/dynamicForm/delete_icon.png" alt="" />
              </div>
            </div>
          </VueDraggable>
          <MenuBar
            :currentPage="currentPage"
            :theme="pageConfigs.theme"
            @pageChange="pageChangeHandler"
          />
        </div>
      </div>
      <div class="props-part">
        <div v-if="currentPropsType">
          <component
            :is="currentPropsType"
            :dataset="currentSelect"
            :theme="pageConfigs.theme"
            :currentPage="currentPage"
            ref="propsComponentIns"
          ></component>
        </div>
        <div v-show="!currentPropsType">
          <MiniInfoEdit
            :dataset="miniResource"
            @baseInfoActive="setItemActive"
            ref="miniInfoForm"
          />
        </div>
      </div>
    </div>
    <PublishDialog
      :dataset="publish"
      @close="publishClose"
      @publish="publishSubmit"
      :areaOptions="areaListMap"
      :merchantList="merchantList"
      :projectList="projectList"
    />
  </div>
</template>

<script>
import ThemeSelector from "./components/themeSelector.vue";
import SingleGraphItem from "./components/singleGraphItem.vue";
import MiniInfoEdit from "./components/miniInfoEdit.vue";
import MenuBar from "./components/menuBar.vue";
import bannerProps from "./propsComponents/banner.vue";
import notifyProps from "./propsComponents/notify.vue";
import newsProps from "./propsComponents/news.vue";
import functionsProps from "./propsComponents/functions.vue";
import services3Props from "./propsComponents/services3.vue";
import services4Props from "./propsComponents/services4.vue";
import banner from "./graphComponents/banner.vue";
import notify from "./graphComponents/notify.vue";
import news from "./graphComponents/news.vue";
import functions from "./graphComponents/functions.vue";
import services3 from "./graphComponents/services3.vue";
import services4 from "./graphComponents/services4.vue";
import PublishDialog from "../config/components/miniAddDialog.vue";
import { componentsList, getComponentsList, getPropsByType } from "./datas.js";
import { VueDraggable } from "vue-draggable-plus";
import { cloneDeep } from "lodash";
import { GenNonDuplicateID } from "@/utils/utils.js";
import {
  getMiniBannerList,
  getMiniNotifyList,
  getMiniNewsList,
  getMiniprogramDetailById,
  saveMiniprogram,
  getMiniprogramList,
  getFunctionList,
  getMerchantList,
} from "@/api/ruge/lego/config";
import { getProjectList } from "@/api/business/base/tenant/map";

export default {
  name: "mini-edit-page",
  components: {
    SingleGraphItem,
    ThemeSelector,
    VueDraggable,
    MenuBar,
    MiniInfoEdit,
    bannerProps,
    banner,
    notifyProps,
    notify,
    newsProps,
    news,
    functionsProps,
    functions,
    services3Props,
    services3,
    services4Props,
    services4,
    PublishDialog,
  },
  data() {
    return {
      componentsList: cloneDeep(componentsList),
      publish: {
        show: false,
        source: "publish",
        displayDatas: {},
      },
      projectList: [],
      areaListMap: [],
      miniprogramTitleEdit: false,
      updateTime: new Date(),
      /**
       *  banner: banner
          通知公告: notify
          新闻资讯: news
          金刚区: functions
          三魔方: services3
          四魔方: services4
      */
      currentPropsType: null,
      currentSelect: {},
      currentPage: "home",
      pageConfigs: {
        home: [],
        more: [],
        theme: "blue",
      },
      miniResource: {},
      merchantList: [],
    };
  },
  watch: {
    currentPage: {
      handler(current) {
        if (current === "more") {
          this.componentsList[1].list = this.componentsList[1].list.filter(
            (item) => item.key === "functions"
          );
        } else {
          const newList = getComponentsList();
          this.componentsList[1].list = getComponentsList()[1].list;
        }
      },
      immediate: true,
    },
  },
  created() {
    this.initDatas();
  },
  methods: {
    async updateHandler() {
      this.clearAllSelect();
      // 基础信息必填&获取数据
      const baseInfo = await this.$refs.miniInfoForm.getBaseInfoForm();
      // 所有功能区必填校验
      if (!this.checkFunctionsValid()) return;
      // 获取pageConfig数据
      const pageConfig = this.pageConfigBuild();
      const params = {
        ...this.miniResource,
        ...baseInfo,
        ...pageConfig,
      };
      console.log("params", params);
      saveMiniprogram(params).then((res) => {
        this.$message.success("保存成功！");
        this.initMiniDetails();
      });
    },
    checkFunctionsValid() {
      for (let key in this.pageConfigs) {
        // 只检查home、more页的
        if (["home", "more"].includes(key)) {
          for (let singleCard of this.pageConfigs[key]) {
            // 检查功能区的
            if (
              ["functions", "services3", "services4"].includes(singleCard.key)
            ) {
              let checkFlag = false;
              for (let singleProp of singleCard.props) {
                const { title, background, jumpMethod, linkObj, functionObj } =
                  singleProp;
                if (
                  !title ||
                  !background ||
                  (jumpMethod === "link" && !linkObj.linkUrl) ||
                  (jumpMethod === "function" && !functionObj.functionId)
                ) {
                  this.$message.warning("请填写必填项！");
                  this.currentPage = key;
                  this.setItemActive(singleCard);
                  singleProp.active = true;
                  this.$nextTick(() => {
                    this.$refs.propsComponentIns.checkValid();
                  });
                  checkFlag = true;
                  return false;
                }
              }
              if (checkFlag) break;
            }
          }
        }
      }
      return true;
    },
    clearAllSelect() {
      this.currentPropsType = null;
      this.currentSelect = {};
      const { home, more } = this.pageConfigs;
      home.forEach((item) => {
        if (Array.isArray(item.props)) {
          item.props.forEach((single) => {
            single.active = false;
          });
        }
        item.active = false;
      });
      more.forEach((item) => {
        if (Array.isArray(item.props)) {
          item.props.forEach((single) => {
            single.active = false;
          });
        }
        item.active = false;
      });
    },
    pageConfigBuild() {
      return {
        pageConfig: JSON.stringify(this.pageConfigs),
      };
    },
    initDatas() {
      this.initComponentDatas();
      this.initMiniDetails();
    },
    initMiniDetails() {
      const { miniprogramId } = this.$route.query;
      if (!miniprogramId) return;
      getMiniprogramDetailById({
        miniprogramId,
      }).then((res) => {
        // 加了外部小程序，之前数据中如果没有outerObj则初始化一下
        let result = this.addFunctionObj(res);
        this.miniResource = result;
        this.pageConfigs = result.pageConfig
          ? JSON.parse(result.pageConfig)
          : {
              home: [],
              more: [],
              theme: "blue",
            };
        this.themeSetHandler(this.pageConfigs.theme);
        this.updateTime = result.lastUpdateDate;
      });
      getMerchantList({
        current: 1,
      }).then((res) => {
        this.merchantList = res.data.rows;
      });
    },
    addFunctionObj(datas) {
      if (!datas.pageConfig) return datas;
      const parsedDatas = JSON.parse(datas.pageConfig);
      for (let singleKey in parsedDatas) {
        if (["home", "more"].includes(singleKey)) {
          parsedDatas[singleKey].forEach((item) => {
            if (
              ["service3", "service4", "functions"].includes(item.key) &&
              item.props.length &&
              !item.props[0].outerObj
            ) {
              item.props.forEach((ele) => {
                if (!ele.outerObj) {
                  ele.outerObj = {
                    appId: "",
                    pageUrl: "",
                  };
                }
              });
            }
          });
        }
      }
      console.log("parsedDatas", parsedDatas);
      datas.pageConfig = JSON.stringify(parsedDatas);
      return datas;
    },
    initComponentDatas() {
      const { miniprogramId } = this.$route.query;
      if (!miniprogramId) return;
      getMiniBannerList({ miniprogramId }).then((res) => {
        this.$store.commit("LEGO_BANNER_LIST", res.rows || []);
      });
      getMiniNotifyList({ miniprogramId }).then((res) => {
        this.$store.commit("LEGO_NOTIFY_LIST", res.data || []);
      });
      getMiniNewsList({ miniprogramId }).then((res) => {
        this.$store.commit("LEGO_NEWS_LIST", res.data.rows || []);
      });
      getFunctionList({ functionStatus: "ENABLE" }).then((res) => {
        this.$store.commit("LEGO_FUNCTION_LIST", res || []);
      });
      getMiniprogramList().then((res) => {
        this.areaListMap = res.map((item) => {
          return {
            areaName: item.areaName,
            areaId: item.areaId,
          };
        });
      });
      getProjectList().then((res) => {
        this.projectList = res || [];
      });
    },
    pageChangeHandler(page) {
      this.currentPage = page;
    },
    themeSetHandler(theme) {
      this.pageConfigs.theme = theme;
      this.componentsList
        .filter((item) => item.key === "theme")[0]
        .list.forEach((item) => {
          item.active = item.key === theme;
        });
    },
    async deleteItem({ id }) {
      await this.$confirm(
        this.$t("message.deleteConfirm"),
        this.$t("commons.warning"),
        {
          confirmButtonText: this.$t("commons.confirm"),
          cancelButtonText: this.$t("commons.cancel"),
          type: "warning",
        }
      );

      for (
        let index = 0;
        index < this.pageConfigs[this.currentPage].length;
        index++
      ) {
        if (this.pageConfigs[this.currentPage][index].id === id) {
          this.pageConfigs[this.currentPage].splice(index, 1);
          this.currentPropsType = null;
          this.currentSelect = {};
          break;
        }
      }
    },
    addHandler(card) {
      this.setItemActive(card.clonedData);
    },
    setItemActive({ id }) {
      this.currentPropsType = null;
      this.currentSelect = {};
      this.pageConfigs[this.currentPage].forEach((item) => {
        if (item.id === id) {
          item.active = true;
          this.currentPropsType = `${item.key}Props`;
          this.currentSelect = item;
        } else {
          item.active = false;
        }
      });
      console.log("this.pageConfigs", this.pageConfigs);
    },
    cloneHandler(element) {
      const cloneObj = cloneDeep(element);
      delete cloneObj.icon;
      cloneObj.id = GenNonDuplicateID();
      cloneObj.active = false;
      cloneObj.props = cloneDeep(getPropsByType(element.key));
      console.log("cloneObj", cloneObj);
      return cloneObj;
    },
    publishHandler() {
      this.publish.displayDatas = this.miniResource;
      this.publish.show = true;
    },
    publishClose() {
      this.publish.displayDatas = null;
      this.publish.show = false;
    },
    publishSubmit(form) {
      const params = {
        ...this.miniResource,
        ...form,
        ...this.pageConfigBuild(),
      };
      console.log("publish-params", params);
      saveMiniprogram(params).then(() => {
        this.$message.success("发布成功！");
        this.initMiniDetails();
        this.publishClose();
      });
    },
    routeBack() {
      this.$router.push({
        path: "/lego/config/list",
        query: {
          miniprogramId: this.$route.query.miniprogramId,
        },
      });
    },
    changeEditState(state) {
      this.miniprogramTitleEdit = state;
      if (state) {
        this.$nextTick(() => {
          this.$refs.miniprogramTitleInput.focus();
          this.$refs.miniprogramTitleInput.select();
        });
      }
    },
  },
};
</script>

<style lang="less" scoped>
.mini-edit-page {
  background: #ffffff;
  height: 100vh;
  .header-line {
    height: 70px;
    background: #ffffff;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 40px;
    border-bottom: 1px solid #e3e8ee;
    .left-part {
      display: flex;
      align-items: center;
      .back-div {
        cursor: pointer;
        display: flex;
        align-items: center;
        font-weight: 600;
        font-size: 18px;
        color: #2a61ff;
        img {
          margin-right: 4px;
          position: relative;
          top: -1px;
        }
        span {
          font-weight: 600;
        }
      }
      .border-line {
        width: 1px;
        height: 12px;
        background: #cbdbe9;
        margin: 0 10px;
      }
      .name-div {
        display: flex;
        align-items: center;
        .name-label {
          display: inline-block;
          max-width: 375px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          font-weight: 600;
          font-size: 18px;
          color: #252d3d;
        }
        .edit-icon {
          cursor: pointer;
          margin-left: 2px;
        }
        .name-input {
          width: 300px;
        }
      }
      .update-time {
        margin-left: 25px;
        font-weight: 400;
        font-size: 14px;
        color: #5d687c;
      }
    }
  }
  .main-line {
    height: calc(100vh - 70px);
    width: 100%;
    display: flex;
    .component-part {
      width: 400px;
      height: 100%;
      background: #fff;
      padding: 20px;
      .single-catelog {
        margin-top: 20px;
        .catelog-title {
          display: flex;
          justify-content: space-between;
          align-items: center;
          border-bottom: 1px solid #e3e8ee;
          padding-bottom: 12px;
          cursor: pointer;
          .label-part {
            display: flex;
            align-items: center;
            img {
              width: 16px;
              height: 16px;
              margin-right: 4px;
              position: relative;
              top: -1px;
            }
            .label-span {
              font-weight: 600;
              font-size: 16px;
              color: #252d3d;
            }
          }
          .icon-part {
            color: #a0aac0;
          }
        }
        .component-list {
          overflow: hidden;
          display: grid;
          grid-template-columns: 1fr 1fr;
          row-gap: 12px;
          column-gap: 20px;
          margin-top: 12px;
          .single-component {
            user-select: none;
            width: 170px;
            height: 100px;
            background: #f7f8fa;
            border-radius: 8px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            cursor: default;
            &:hover {
              background: #f2f3f5;
            }
            &:active {
              background: #eeeff0;
            }
            .component-label {
              font-weight: 400;
              font-size: 12px;
              color: #252d3d;
              margin-top: 5px;
            }
          }
        }
      }
    }
    .graph-part {
      flex: 1;
      height: 100%;
      background: #f7f8fa;
      border-left: 1px solid #e3e8ee;
      border-right: 1px solid #e3e8ee;
      display: flex;
      justify-content: center;
      align-items: center;
      .graph-border {
        width: 375px;
        height: calc(100% - 60px);
        background: #f6f8fa;
        box-shadow: 1px 1px 2px 0px rgba(142, 142, 142, 0.1);
        border: 1px solid #eef2f6;
        background-repeat: no-repeat;
        position: relative;
        .mini-name-line {
          cursor: default;
          position: absolute;
          top: 60px;
          left: 12px;
          font-weight: 500;
          font-size: 14px;
          color: #30313b;
          .name-span {
            display: inline-block;
            max-width: 140px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            margin: 0 4px;
          }
        }
        .container {
          height: calc(100% - 176px);
          width: 100%;
          overflow: auto;
          .single-item {
            border: 2px solid #00000000;
            position: relative;
            width: calc(100% - 24px);
            margin: 12px;
            // background: #fff;
            border-radius: 8px;
            .delete-container {
              z-index: 2;
              width: 24px;
              height: 20px;
              position: absolute;
              bottom: 0;
              right: 0;
              background: #2a61ff;
              display: flex;
              align-items: center;
              justify-content: center;
              cursor: pointer;
            }
            &:hover {
              border: 2px solid #2a61ff50;
            }
          }
          .error-node {
            border: 2px solid #ff0000 !important;
          }
          .single-item-active {
            border: 2px solid #2a61ff;
            &:hover {
              border: 2px solid #2a61ff;
            }
          }
          .drag-style {
            width: calc(100% - 24px);
            margin-left: 12px;
            // background: rgba(42, 97, 255, 0.1);
            border: 1px dotted #2a61ff;
            .component-image {
              opacity: 0;
            }
            .component-label {
              opacity: 0;
            }
          }
        }
      }
    }
    .props-part {
      padding: 15px 20px;
      width: 400px;
      height: 100%;
      background: #fff;
      overflow: auto;
    }
  }
}
</style>