<template>
  <div class="props-edit-banner">
    <div class="single-item">
      <div class="item-label-line">banner数量</div>
      <el-input-number
        v-model="dataset.props.count"
        :min="1"
        :max="10"
        @change="freshList"
      ></el-input-number>
    </div>
    <div class="single-item">
      <div class="item-label-line">banner预览</div>
      <div
        :class="['single-preview', !item && 'single-preview-border']"
        v-for="(item, index) in imageList"
        :key="index"
      >
        <el-image
          v-if="item.fileId"
          placeholder="加载中..."
          :src="imageRender(item.fileId)"
          lazy
        ></el-image>
        <i v-else class="el-icon-picture-outline"></i>
      </div>
    </div>
  </div>
</template>

<script>
import { cloneDeep } from "lodash";
import { envInfo } from "@/constants/envInfo";

export default {
  name: "props-edit-banner",
  props: {
    dataset: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      imageList: [],
      resourceList: [],
    };
  },
  created() {
    this.freshList();
  },
  methods: {
    freshList() {
      const tempList = cloneDeep(this.$store.getters.legoBannerList);
      const diff = this.dataset.props.count - tempList.length;
      if (diff > 0) {
        this.imageList = cloneDeep(tempList.concat(new Array(diff).fill({})));
      } else {
        this.imageList = cloneDeep(
          tempList.splice(0, this.dataset.props.count)
        );
      }
    },
    imageRender(fileId) {
      return (
        envInfo.bgApp.archivePath +
        "/param/archive/download?dlType=DefaultDownload&fType=image&fi=" +
        fileId
      );
    },
  },
};
</script>

<style lang="less" scoped>
.props-edit-banner {
  .single-item {
    border-bottom: 1px solid #e3e8ee;
    margin-bottom: 24px;
    .item-label-line {
      font-weight: 600;
      font-size: 14px;
      color: #252d3d;
      margin-bottom: 16px;
    }
    .el-input-number {
      margin-bottom: 24px;
    }
    .single-preview {
      width: 100%;
      height: 160px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 10px;
      margin-bottom: 12px;
      border: 1px solid #f2f2f2;
      .el-icon-picture-outline {
        font-size: 40px;
        color: #ccc;
      }
      img {
        max-height: 100%;
        max-width: 100%;
      }
    }
    .single-preview-border {
      border: 1px solid #e3e8ee;
    }
  }
}
</style>