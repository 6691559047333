var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "single-function-props" },
    [
      _c(
        "el-form",
        {
          ref: "propsForm",
          attrs: {
            model: _vm.dataset,
            rules: _vm.formRules,
            "label-width": "120px",
            "label-position": "top",
          },
        },
        [
          _c(
            "el-form-item",
            {
              staticClass: "single-form-item",
              attrs: { label: "标题", prop: "title" },
            },
            [
              _c("el-input", {
                attrs: { maxlength: "4", placeholder: "请输入" },
                model: {
                  value: _vm.dataset.title,
                  callback: function ($$v) {
                    _vm.$set(_vm.dataset, "title", $$v)
                  },
                  expression: "dataset.title",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              staticClass: "single-form-item",
              attrs: { label: "简介", prop: "description" },
            },
            [
              _c("el-input", {
                attrs: { placeholder: "请输入" },
                model: {
                  value: _vm.dataset.description,
                  callback: function ($$v) {
                    _vm.$set(_vm.dataset, "description", $$v)
                  },
                  expression: "dataset.description",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              staticClass: "single-form-item",
              attrs: { label: "背景图", prop: "background" },
            },
            [
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.uploadloading,
                      expression: "uploadloading",
                    },
                  ],
                  staticClass: "upload-container",
                },
                [
                  _c(
                    "el-upload",
                    {
                      staticClass: "avatar-uploader",
                      attrs: {
                        action: _vm.uploadPath,
                        "with-credentials": true,
                        "show-file-list": false,
                        "on-success": _vm.handleAvatarSuccess,
                        "before-upload": _vm.beforeAvatarUpload,
                      },
                    },
                    [
                      _vm.dataset.background
                        ? _c("img", {
                            staticClass: "avatar",
                            attrs: {
                              src: _vm.imageRender(_vm.dataset.background),
                            },
                          })
                        : _c("i", {
                            staticClass: "el-icon-plus avatar-uploader-icon",
                          }),
                    ]
                  ),
                  _c("div", { staticClass: "tips-container" }, [
                    _c("div", [
                      _c("div", [_vm._v("温馨提示：")]),
                      _c("div", [
                        _vm._v(
                          "最多上传1张，图片大小不能超过2m，图片格式为jpg，png。"
                        ),
                      ]),
                    ]),
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.dataset.background,
                            expression: "dataset.background",
                          },
                        ],
                        staticClass: "delete-icon",
                        on: { click: _vm.deleteHandler },
                      },
                      [_c("i", { staticClass: "el-icon-delete" })]
                    ),
                  ]),
                ],
                1
              ),
            ]
          ),
          _c(
            "el-form-item",
            {
              staticClass: "single-form-item no-border",
              attrs: { label: "跳转方式", prop: "jumpMethod" },
            },
            [
              _c(
                "el-radio-group",
                {
                  model: {
                    value: _vm.dataset.jumpMethod,
                    callback: function ($$v) {
                      _vm.$set(_vm.dataset, "jumpMethod", $$v)
                    },
                    expression: "dataset.jumpMethod",
                  },
                },
                [
                  _c("el-radio", { attrs: { label: "link" } }, [
                    _vm._v("外部链接"),
                  ]),
                  _c("el-radio", { attrs: { label: "function" } }, [
                    _vm._v("选择功能"),
                  ]),
                  _c("el-radio", { attrs: { label: "outer" } }, [
                    _vm._v("外部小程序"),
                  ]),
                ],
                1
              ),
              _vm.dataset.jumpMethod === "link"
                ? _c(
                    "div",
                    [
                      _c(
                        "div",
                        {
                          staticClass:
                            "second-level-title second-level-require",
                        },
                        [_vm._v("链接地址")]
                      ),
                      _c("el-input", {
                        attrs: { placeholder: "请输入" },
                        model: {
                          value: _vm.dataset.linkObj.linkUrl,
                          callback: function ($$v) {
                            _vm.$set(_vm.dataset.linkObj, "linkUrl", $$v)
                          },
                          expression: "dataset.linkObj.linkUrl",
                        },
                      }),
                    ],
                    1
                  )
                : _vm.dataset.jumpMethod === "function"
                ? _c(
                    "div",
                    [
                      _c(
                        "div",
                        {
                          staticClass:
                            "second-level-title second-level-require",
                        },
                        [_vm._v("选择功能")]
                      ),
                      _c("el-cascader", {
                        staticStyle: { width: "100%" },
                        attrs: {
                          options: _vm.functionList,
                          props: { expandTrigger: "hover" },
                        },
                        on: { change: _vm.functionChange },
                        model: {
                          value: _vm.dataset.functionObj.functionId,
                          callback: function ($$v) {
                            _vm.$set(_vm.dataset.functionObj, "functionId", $$v)
                          },
                          expression: "dataset.functionObj.functionId",
                        },
                      }),
                    ],
                    1
                  )
                : _vm.dataset.jumpMethod === "outer"
                ? _c(
                    "div",
                    [
                      _c(
                        "div",
                        {
                          staticClass:
                            "second-level-title second-level-require",
                        },
                        [_vm._v("appId")]
                      ),
                      _c("el-input", {
                        attrs: { placeholder: "请输入" },
                        model: {
                          value: _vm.dataset.outerObj.appId,
                          callback: function ($$v) {
                            _vm.$set(_vm.dataset.outerObj, "appId", $$v)
                          },
                          expression: "dataset.outerObj.appId",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
          _vm.dataset.jumpMethod === "link"
            ? _c(
                "div",
                [
                  _c("div", { staticClass: "second-level-title" }, [
                    _vm._v("外部链接说明"),
                  ]),
                  _c("el-input", {
                    staticStyle: { "margin-top": "10px" },
                    attrs: { type: "textarea", placeholder: "请输入" },
                    model: {
                      value: _vm.dataset.linkObj.linkDescription,
                      callback: function ($$v) {
                        _vm.$set(_vm.dataset.linkObj, "linkDescription", $$v)
                      },
                      expression: "dataset.linkObj.linkDescription",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm.dataset.jumpMethod === "outer"
            ? _c(
                "div",
                [
                  _c("div", { staticClass: "second-level-title" }, [
                    _vm._v("页面地址"),
                  ]),
                  _c("el-input", {
                    attrs: { placeholder: "请输入" },
                    model: {
                      value: _vm.dataset.outerObj.pageUrl,
                      callback: function ($$v) {
                        _vm.$set(_vm.dataset.outerObj, "pageUrl", $$v)
                      },
                      expression: "dataset.outerObj.pageUrl",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
      _vm.source === "functions"
        ? _c(
            "div",
            { staticClass: "buttons-line" },
            [
              _c(
                "r-button",
                {
                  attrs: { type: "danger" },
                  on: { click: _vm.propsDeleteHandler },
                },
                [_vm._v("删除")]
              ),
              _c(
                "r-button",
                { attrs: { plain: "" }, on: { click: _vm.saveHandler } },
                [_vm._v("保存")]
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }