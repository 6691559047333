var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "props-edit-news" }, [
    _c(
      "div",
      { staticClass: "single-item" },
      [
        _c("div", { staticClass: "item-label-line" }, [_vm._v("数量选择")]),
        _c("el-input-number", {
          attrs: { min: 1, max: 10 },
          on: { change: _vm.freshList },
          model: {
            value: _vm.dataset.props.count,
            callback: function ($$v) {
              _vm.$set(_vm.dataset.props, "count", $$v)
            },
            expression: "dataset.props.count",
          },
        }),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "single-item" },
      [
        _c("div", { staticClass: "item-label-line" }, [_vm._v("预览")]),
        _vm._m(0),
        _vm._l(_vm.newsList, function (item, index) {
          return _c(
            "div",
            {
              key: index,
              class: ["single-preview", !item && "single-preview-border"],
            },
            [
              item.id
                ? _c(
                    "div",
                    { staticClass: "single-news" },
                    [
                      _c("el-image", {
                        staticClass: "news-image",
                        attrs: {
                          placeholder: "加载中...",
                          src: _vm.imageRender(item.photoIdListId[0]),
                          lazy: "",
                        },
                      }),
                      _c("div", { staticClass: "right-part" }, [
                        _c("div", { staticClass: "title-line" }, [
                          _vm._v(
                            "\n            " +
                              _vm._s(item.activityTitle) +
                              "\n          "
                          ),
                        ]),
                        _c("div", { staticClass: "address-line" }, [
                          _vm._v(
                            "\n            " +
                              _vm._s(item.activityAddress) +
                              "\n          "
                          ),
                        ]),
                        _c("div", { staticClass: "date-line" }, [
                          _vm._v(
                            "\n            " +
                              _vm._s(
                                _vm._f("dateFormat")(
                                  item.updateTime,
                                  "YYYY年MM月DD日 HH:mm:ss"
                                )
                              ) +
                              "\n          "
                          ),
                        ]),
                      ]),
                    ],
                    1
                  )
                : _c("i", { staticClass: "el-icon-picture-outline" }),
            ]
          )
        }),
      ],
      2
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "news-title-line" }, [
      _c("span", { staticClass: "news-title-label" }, [_vm._v(" 精彩推荐 ")]),
      _c("span", { staticClass: "more-span" }, [
        _vm._v("\n        更多\n        "),
        _c("i", { staticClass: "el-icon-arrow-right" }),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }