var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "props-edit-notify" }, [
    _c("div", { staticClass: "single-item" }, [
      _c("div", { staticClass: "item-label-line" }, [_vm._v("通知公告")]),
      _c("p", { staticClass: "notify-content" }, [
        _vm._v("\n      " + _vm._s(_vm.notify) + "\n    "),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }