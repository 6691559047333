var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "mini-edit-page" },
    [
      _c("div", { staticClass: "header-line" }, [
        _c("div", { staticClass: "left-part" }, [
          _c("div", { staticClass: "back-div", on: { click: _vm.routeBack } }, [
            _c("img", {
              attrs: {
                src: require("@/assets/images/go_back_icon.png"),
                alt: "",
              },
            }),
            _c("span", [_vm._v("返回")]),
          ]),
          _c("div", { staticClass: "border-line" }),
          _c(
            "div",
            { staticClass: "name-div" },
            [
              !_vm.miniprogramTitleEdit
                ? _c("span", { staticClass: "name-label" }, [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.miniResource.miniprogramTitle) +
                        "\n        "
                    ),
                  ])
                : _c("el-input", {
                    ref: "miniprogramTitleInput",
                    staticClass: "name-input",
                    attrs: { maxlength: "30", "show-word-limit": true },
                    on: {
                      blur: function ($event) {
                        return _vm.changeEditState(false)
                      },
                    },
                    nativeOn: {
                      keyup: function ($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k(
                            $event.keyCode,
                            "enter",
                            13,
                            $event.key,
                            "Enter"
                          )
                        )
                          return null
                        return _vm.changeEditState(false)
                      },
                    },
                    model: {
                      value: _vm.miniResource.miniprogramTitle,
                      callback: function ($$v) {
                        _vm.$set(_vm.miniResource, "miniprogramTitle", $$v)
                      },
                      expression: "miniResource.miniprogramTitle",
                    },
                  }),
              _c("img", {
                staticClass: "edit-icon",
                attrs: {
                  src: require("@/assets/images/bms/edit_icon.png"),
                  alt: "",
                },
                on: {
                  click: function ($event) {
                    return _vm.changeEditState(true)
                  },
                },
              }),
            ],
            1
          ),
          _c("div", { staticClass: "update-time" }, [
            _vm._v(
              "\n        最近保存时间: " +
                _vm._s(
                  _vm._f("dateFormat")(_vm.updateTime, "YYYY-MM-DD HH:mm:ss")
                ) +
                "\n      "
            ),
          ]),
        ]),
        _c(
          "div",
          { staticClass: "right-part" },
          [
            _c(
              "r-button",
              {
                attrs: { type: "normal" },
                on: {
                  click: function ($event) {
                    return _vm.publishHandler("PUBLISH")
                  },
                },
              },
              [
                _c("i", {
                  staticClass: "el-icon-position",
                  staticStyle: { "margin-right": "4px" },
                }),
                _vm._v("\n        发布"),
              ]
            ),
            _c(
              "r-button",
              {
                attrs: { type: "normal", plain: "" },
                on: {
                  click: function ($event) {
                    return _vm.updateHandler()
                  },
                },
              },
              [_vm._v("保存")]
            ),
          ],
          1
        ),
      ]),
      _c("div", { staticClass: "main-line" }, [
        _c(
          "div",
          { staticClass: "component-part" },
          _vm._l(_vm.componentsList, function (item) {
            return _c("div", { key: item.key, staticClass: "single-catelog" }, [
              _c(
                "div",
                {
                  staticClass: "catelog-title",
                  on: {
                    click: function ($event) {
                      item.active = !item.active
                    },
                  },
                },
                [
                  _c("div", { staticClass: "label-part" }, [
                    _c("img", { attrs: { src: item.icon, alt: "" } }),
                    _c("span", { staticClass: "label-span" }, [
                      _vm._v(
                        "\n              " +
                          _vm._s(item.label) +
                          "\n            "
                      ),
                    ]),
                  ]),
                  _c("div", { staticClass: "icon-part" }, [
                    !item.active
                      ? _c("i", { staticClass: "el-icon-caret-right" })
                      : _c("i", { staticClass: "el-icon-caret-bottom" }),
                  ]),
                ]
              ),
              _c(
                "div",
                {
                  style: {
                    height: item.active ? "auto" : "0",
                    overflow: "hidden",
                  },
                },
                [
                  item.key === "theme"
                    ? _c(
                        "div",
                        [
                          _c("ThemeSelector", {
                            attrs: { dataset: item.list },
                            on: { updateTheme: _vm.themeSetHandler },
                          }),
                        ],
                        1
                      )
                    : _c(
                        "div",
                        [
                          _c(
                            "VueDraggable",
                            {
                              staticClass: "container component-list",
                              attrs: {
                                animation: "150",
                                ghostClass: "left-ghost",
                                sort: false,
                                group: {
                                  name: "components",
                                  pull: "clone",
                                  put: false,
                                },
                                filter: ".ignore",
                                clone: _vm.cloneHandler,
                              },
                              model: {
                                value: item.list,
                                callback: function ($$v) {
                                  _vm.$set(item, "list", $$v)
                                },
                                expression: "item.list",
                              },
                            },
                            _vm._l(item.list, function (single) {
                              return _c(
                                "div",
                                {
                                  key: single.key,
                                  staticClass: "single-component",
                                  attrs: { "data-type": single.key },
                                },
                                [
                                  _c("img", {
                                    staticClass: "component-image",
                                    attrs: { src: single.icon, alt: "" },
                                  }),
                                  _c(
                                    "div",
                                    { staticClass: "component-label" },
                                    [
                                      _vm._v(
                                        "\n                  " +
                                          _vm._s(single.label) +
                                          "\n                "
                                      ),
                                    ]
                                  ),
                                ]
                              )
                            }),
                            0
                          ),
                        ],
                        1
                      ),
                ]
              ),
            ])
          }),
          0
        ),
        _c(
          "div",
          {
            staticClass: "graph-part",
            on: {
              click: function ($event) {
                $event.stopPropagation()
                return _vm.setItemActive.apply(null, arguments)
              },
            },
          },
          [
            _c(
              "div",
              {
                staticClass: "graph-border",
                style: {
                  backgroundImage:
                    "url(" +
                    require(`@/assets/images/lego/home_background_${_vm.pageConfigs.theme}.png`) +
                    ")",
                },
              },
              [
                _c("div", { staticClass: "mini-name-line" }, [
                  _c("i", { staticClass: "el-icon-location-outline" }),
                  _c("span", { staticClass: "name-span" }, [
                    _vm._v(
                      "\n            " +
                        _vm._s(_vm.miniResource.miniprogramTitle) +
                        "\n          "
                    ),
                  ]),
                  _c("i", { staticClass: "el-icon-arrow-down" }),
                ]),
                _c("img", {
                  attrs: {
                    src: require("@/assets/images/lego/home_head_img.png"),
                    alt: "",
                  },
                }),
                _c(
                  "VueDraggable",
                  {
                    staticClass: "container",
                    attrs: {
                      animation: "150",
                      ghostClass: "drag-style",
                      group: "components",
                      filter: ".ignore",
                    },
                    on: { add: _vm.addHandler },
                    model: {
                      value: _vm.pageConfigs[_vm.currentPage],
                      callback: function ($$v) {
                        _vm.$set(_vm.pageConfigs, _vm.currentPage, $$v)
                      },
                      expression: "pageConfigs[currentPage]",
                    },
                  },
                  _vm._l(_vm.pageConfigs[_vm.currentPage], function (item) {
                    return _c(
                      "div",
                      {
                        key: item.id,
                        class: [
                          `single-item-${item.key}`,
                          `single-item`,
                          item.active && "single-item-active",
                        ],
                        attrs: { id: item.id },
                        on: {
                          click: function ($event) {
                            $event.stopPropagation()
                            return _vm.setItemActive(item)
                          },
                        },
                      },
                      [
                        _c(item.key, {
                          key: item.id,
                          ref: "graphSingleRef",
                          refInFor: true,
                          tag: "component",
                          attrs: {
                            dataset: item,
                            active: item.active,
                            theme: _vm.pageConfigs.theme,
                            currentPage: _vm.currentPage,
                          },
                          on: {
                            setActive: function ($event) {
                              return _vm.setItemActive(item)
                            },
                          },
                        }),
                        item.active
                          ? _c(
                              "div",
                              {
                                staticClass: "delete-container",
                                on: {
                                  click: function ($event) {
                                    return _vm.deleteItem(item)
                                  },
                                },
                              },
                              [
                                _c("img", {
                                  attrs: {
                                    src: require("@/assets/images/dynamicForm/delete_icon.png"),
                                    alt: "",
                                  },
                                }),
                              ]
                            )
                          : _vm._e(),
                      ],
                      1
                    )
                  }),
                  0
                ),
                _c("MenuBar", {
                  attrs: {
                    currentPage: _vm.currentPage,
                    theme: _vm.pageConfigs.theme,
                  },
                  on: { pageChange: _vm.pageChangeHandler },
                }),
              ],
              1
            ),
          ]
        ),
        _c("div", { staticClass: "props-part" }, [
          _vm.currentPropsType
            ? _c(
                "div",
                [
                  _c(_vm.currentPropsType, {
                    ref: "propsComponentIns",
                    tag: "component",
                    attrs: {
                      dataset: _vm.currentSelect,
                      theme: _vm.pageConfigs.theme,
                      currentPage: _vm.currentPage,
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: !_vm.currentPropsType,
                  expression: "!currentPropsType",
                },
              ],
            },
            [
              _c("MiniInfoEdit", {
                ref: "miniInfoForm",
                attrs: { dataset: _vm.miniResource },
                on: { baseInfoActive: _vm.setItemActive },
              }),
            ],
            1
          ),
        ]),
      ]),
      _c("PublishDialog", {
        attrs: {
          dataset: _vm.publish,
          areaOptions: _vm.areaListMap,
          merchantList: _vm.merchantList,
          projectList: _vm.projectList,
        },
        on: { close: _vm.publishClose, publish: _vm.publishSubmit },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }