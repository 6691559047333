<template>
  <div class="props-edit-notify">
    <div class="single-item">
      <div class="item-label-line">通知公告</div>
      <p class="notify-content">
        {{ notify }}
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: "props-edit-notify",
  data() {
    return {
      notify: this.$store.getters.legonNotifyList.noticeTitle,
    };
  },
};
</script>

<style lang="less" scoped>
.props-edit-notify {
  .single-item {
    .item-label-line {
      font-weight: 600;
      font-size: 14px;
      color: #252d3d;
      margin-bottom: 16px;
    }
    .notify-content {
      cursor: default;
      background: #f7f8fa;
      padding: 10px 12px;
      font-weight: 500;
      font-size: 14px;
      color: #252d3d;
      line-height: 20px;
    }
  }
}
</style>