<template>
  <div class="props-edit-news">
    <div class="single-item">
      <div class="item-label-line">数量选择</div>
      <el-input-number
        v-model="dataset.props.count"
        :min="1"
        :max="10"
        @change="freshList"
      ></el-input-number>
    </div>
    <div class="single-item">
      <div class="item-label-line">预览</div>
      <div class="news-title-line">
        <span class="news-title-label"> 精彩推荐 </span>
        <span class="more-span">
          更多
          <i class="el-icon-arrow-right"></i>
        </span>
      </div>
      <div
        :class="['single-preview', !item && 'single-preview-border']"
        v-for="(item, index) in newsList"
        :key="index"
      >
        <div class="single-news" v-if="item.id">
          <el-image
            class="news-image"
            placeholder="加载中..."
            :src="imageRender(item.photoIdListId[0])"
            lazy
          ></el-image>
          <div class="right-part">
            <div class="title-line">
              {{ item.activityTitle }}
            </div>
            <div class="address-line">
              {{ item.activityAddress }}
            </div>
            <div class="date-line">
              {{ item.updateTime | dateFormat("YYYY年MM月DD日 HH:mm:ss") }}
            </div>
          </div>
        </div>
        <i v-else class="el-icon-picture-outline"></i>
      </div>
    </div>
  </div>
</template>

<script>
import { cloneDeep } from "lodash";
import { envInfo } from "@/constants/envInfo";

export default {
  name: "props-edit-news",
  props: {
    dataset: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      newsList: [],
    };
  },
  watch: {
    dataset: {
      handler() {
        this.freshList();
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    imageRender(fileId) {
      return (
        envInfo.bgApp.archivePath +
        "/param/archive/download?dlType=DefaultDownload&fType=image&fi=" +
        fileId
      );
    },
    freshList() {
      if (this.dataset.props && this.dataset.props.count) {
        const tempList = cloneDeep(this.$store.getters.legonNewsList);
        const diff = this.dataset.props.count - tempList.length;
        this.newsList =
          this.dataset.props.count - tempList.length > 0
            ? tempList.concat(new Array(diff).fill({}))
            : tempList.splice(0, this.dataset.props.count);
      }
    },
  },
};
</script>

<style lang="less" scoped>
.props-edit-news {
  .single-item {
    border-bottom: 1px solid #e3e8ee;
    margin-bottom: 24px;
    .item-label-line {
      font-weight: 600;
      font-size: 14px;
      color: #252d3d;
      margin-bottom: 16px;
    }
    .news-title-line {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 12px;
      .news-title-label {
        font-weight: 500;
        font-size: 14px;
        color: #30313b;
      }
      .more-span {
        font-weight: 400;
        font-size: 12px;
        color: #c9cbd2;
        cursor: default;
      }
    }
    .el-input-number {
      margin-bottom: 24px;
    }
    .single-preview {
      width: 100%;
      height: 84px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 10px;
      margin-bottom: 12px;
      border: 1px solid #f2f2f2;
      .el-icon-picture-outline {
        font-size: 40px;
        color: #ccc;
      }
      .single-news {
        height: 100%;
        width: 100%;
        padding: 12px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .news-image {
          width: 90px;
          height: 60px;
          border-radius: 5px;
        }
        .right-part {
          flex: 1;
          margin-left: 12px;
          .title-line {
            font-weight: 500;
            font-size: 14px;
            color: #30313b;
          }
          .address-line {
            font-weight: 400;
            font-size: 12px;
            color: #828798;
            margin-top: 6px;
          }
          .date-line {
            font-weight: 400;
            font-size: 12px;
            color: #c9cbd2;
            margin-top: 14px;
          }
        }
      }
    }
    .single-preview-border {
      border: 1px solid #e3e8ee;
    }
  }
}
</style>