<template>
  <div class="theme-selector">
    <div
      :class="['single-card', item.active && 'single-card-active']"
      v-for="item in dataset"
      :key="item.key"
      @click="themeClick(item)"
    >
      <div
        class="color-line"
        :style="{
          background: item.color,
        }"
      ></div>
      <div class="label-line">
        {{ item.label }}
      </div>
      <img
        class="corner-img"
        :src="
          require(`../../../../../assets/images/bms/example_${
            item.active ? 'selected' : 'unselected'
          }.png`)
        "
        alt=""
      />
    </div>
  </div>
</template>

<script>
export default {
  name: "theme-selector",
  /**
   * {
        key: 'blue',
        label: '锦绣蓝',
        color: '#1A4CEC',
        active: true,
      },
   */
  props: {
    dataset: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  methods: {
    themeClick({ key }) {
      this.dataset.forEach((item) => {
        item.active = item.key === key;
      });
      this.$emit("updateTheme", key);
    },
  },
};
</script>

<style lang="less" scoped>
.theme-selector {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 12px;
  margin-top: 12px;
  position: relative;
  .single-card {
    width: 170px;
    height: 100px;
    background: #f7f8fa;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    border-radius: 8px;
    border: 1px solid #f7f8fa;
    position: relative;
    cursor: pointer;
    &:hover {
      background: #f2f3f5;
    }
    &:active {
      background: #eeeff0;
    }
    .color-line {
      width: 130px;
      height: 40px;
      border-radius: 10px;
    }
    .label-line {
      font-weight: 400;
      font-size: 12px;
      color: #252d3d;
    }
  }
  .single-card-active {
    border: 1px solid #2a61ff;
  }
  .corner-img {
    position: absolute;
    right: -1px;
    top: -1px;
  }
}
</style>