<template>
  <div class="props-edit-functions">
    <div v-if="dataset.props.filter((item) => item.active).length === 0">
      <div v-if="currentPage === 'more'">
        <div class="label-line">标题</div>
        <el-input v-model="dataset.label"></el-input>
      </div>
      <div class="example-container">
        <div
          class="single-card"
          v-for="(item, index) in dataset.props.filter((item) => item.checked)"
          :key="index"
        >
          <img class="card-icon" :src="imageRender(item.background)" alt="" />
          <div class="card-title">
            {{ item.title }}
          </div>
        </div>
      </div>
    </div>
    <div v-else>
      <SingleFunctionProps
        ref="functionPropsIns"
        source="functions"
        :dataset="dataset.props.filter((item) => item.active)[0]"
        @submit="submitHandler"
      />
    </div>
  </div>
</template>

<script>
import SingleFunctionProps from "../components/singleFunctionProps.vue";
import { envInfo } from "@/constants/envInfo";

export default {
  name: "props-edit-functions",
  components: {
    SingleFunctionProps,
  },
  props: {
    dataset: {
      type: Object,
      default() {
        return {};
      },
    },
    theme: {
      type: String,
      default() {
        return "blue";
      },
    },
    currentPage: {
      type: String,
      default() {
        return "home";
      },
    },
  },
  methods: {
    checkValid() {
      this.$refs.functionPropsIns.checkFormValid();
    },
    submitHandler() {
      // 修改对象的checked
    },
    imageRender(fileId) {
      return (
        envInfo.bgApp.archivePath +
        "/param/archive/download?dlType=DefaultDownload&fType=image&fi=" +
        fileId
      );
    },
  },
};
</script>

<style lang="less" scoped>
.props-edit-functions {
  .label-line {
    font-weight: 700;
    font-size: 14px;
    color: #606266;
    margin-bottom: 10px;
  }
  .example-container {
    display: flex;
    flex-wrap: wrap;
    margin-top: 20px;
    gap: 15px 0;
    .single-card {
      width: 25%;
      height: 52px;
      border: 1px solid #fff;
      border-radius: 3px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      .card-icon {
        width: 32px;
        height: 32px;
      }
      .card-title {
        font-weight: 400;
        font-size: 12px;
        color: #30313b;
        text-align: center;
      }
    }
  }
}
</style>